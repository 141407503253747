import api from '@/api';

const state = {
    roles: {
        isCreating: false,
        isLoading: false,
        data: [],
    },
};

const getters = {
    isCreatingRole: state => state.roles.isCreating,
    roles: state => state.roles.data,
    hasRoles: state => state.roles.data.length > 0,
    isLoadingRoles: state => state.roles.isLoading,
};

const mutations = {
    setRoles(state, roles) {
        state.roles.data = roles;
    },
    setLoadingRoles(state, isLoading) {
        state.roles.isLoading = isLoading;
    },
    setIsCreatingRole(state, isCreating) {
        state.roles.isCreating = isCreating;
    },
    addRole(state, role) {
        state.roles.data.push(role);
    },
};

const actions = {
    async getRoles({ getters, commit }) {
        if (!getters.hasRoles && !getters.isLoadingRoles) {
            commit('setLoadingRoles', true);
            let { data: { data: roles } } = await api.get('roles');
            commit('setRoles', roles);
            commit('setLoadingRoles', false);
        }
    },
    async createRole({ commit }, newRole) {
        commit('setIsCreatingRole', true);
        let { data: { data: role } } = await api.post('roles', newRole);
        commit('addRole', role);
        commit('setIsCreatingRole', false);

        return role;
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};