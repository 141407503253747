import { mapGetters, mapActions, } from 'vuex';

export default {
    created() {
        this.getMarkets();
    },
    methods: {
        ...mapActions([
            'getMarkets',
        ]),
        marketNameFormatter(marketId) {
            if (!marketId) {
                return 'Global';
            }

            if (this.markets.findIndex(market => market.id === marketId) > -1) {
                return this.markets.find(market => market.id === marketId).name;
            }

            return 'n/a';
        },
    },
    computed: {
        ...mapGetters([
            'markets',
        ]),
    },
};
