import api from '@/api';

const state = {
    markets: {
        isLoading: false,
        data: [],
    },
};

const getters = {
    markets: state => state.markets.data,
    hasMarkets: state => state.markets.data.length > 0,
    isLoadingMarkets: state => state.markets.isLoading,
};

const mutations = {
    setMarkets(state, markets) {
        state.markets.data = markets;
    },
    setLoadingMarkets(state, isLoading) {
        state.markets.isLoading = isLoading;
    },
};

const actions = {
    async getMarkets({ getters, commit }) {
        if (!getters.hasMarkets && !getters.isLoadingMarkets) {
            commit('setLoadingMarkets', true);
            let { data: { data: markets } } = await api.get('markets');
            commit('setMarkets', markets);
            commit('setLoadingMarkets', false);
        }
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};