var render = function render(){var _vm=this,_c=_vm._self._c;return _c('aside',{staticClass:"main-sidebar"},[_c('section',{staticClass:"sidebar"},[_c('ul',{ref:"tree",staticClass:"sidebar-menu"},[_c('router-link',{attrs:{"to":"/","active-class":"active","tag":"li","exact":""}},[_c('a',[_c('i',{staticClass:"fa fa-dashboard"}),_c('span',[_vm._v("Dashboard")])])]),(
                    _vm.hasPermissions(_vm.userAbilities, {
                        ability: 'manage-highlights',
                    })
                )?_c('router-link',{attrs:{"to":"/highlights","active-class":"active","tag":"li"}},[_c('a',[_c('i',{staticClass:"fa fa-star-half-full"}),_c('span',[_vm._v("Highlights")])])]):_vm._e(),(
                    _vm.hasPermissions(_vm.userAbilities, {
                        ability: 'manage-stories',
                    })
                )?_c('li',{staticClass:"treeview"},[_vm._m(0),_c('ul',{staticClass:"treeview-menu"},[_c('router-link',{attrs:{"to":"/stories","tag":"li","active-class":"active"}},[_c('a',[_vm._v("All Stories")])]),_c('router-link',{attrs:{"to":"/new-story","tag":"li","active-class":"active"}},[_c('a',[_vm._v("Add new Story")])])],1)]):_vm._e(),(
                    _vm.hasPermissions(_vm.userAbilities, {
                        ability: 'manage-videos',
                    })
                )?_c('li',{staticClass:"treeview"},[_vm._m(1),_c('ul',{staticClass:"treeview-menu"},[_c('router-link',{attrs:{"to":"/videos","tag":"li","active-class":"active"}},[_c('a',[_vm._v("All Videos")])]),_c('router-link',{attrs:{"to":"/videos/new","tag":"li","active-class":"active"}},[_c('a',[_vm._v("Add new Video")])])],1)]):_vm._e(),(
                    _vm.hasPermissions(_vm.userAbilities, {
                        ability: 'manage-podcasts',
                    })
                )?_c('li',{staticClass:"treeview"},[_vm._m(2),_c('ul',{staticClass:"treeview-menu"},[_c('router-link',{attrs:{"to":"/podcasts","tag":"li","active-class":"active"}},[_c('a',[_vm._v("All Podcasts")])]),_c('router-link',{attrs:{"to":"/podcasts/new","tag":"li","active-class":"active"}},[_c('a',[_vm._v("Add new Podcast")])])],1)]):_vm._e(),(
                    _vm.hasPermissions(_vm.userAbilities, {
                        ability: 'manage-event-details',
                    })
                )?_c('li',{staticClass:"treeview"},[_vm._m(3),_c('ul',{staticClass:"treeview-menu"},[_c('router-link',{attrs:{"to":"/events","tag":"li","active-class":"active"}},[_c('a',[_vm._v("Events")])]),_c('router-link',{attrs:{"to":"/new-event","tag":"li","active-class":"active"}},[_c('a',[_vm._v("Add new Event")])]),_c('router-link',{attrs:{"to":"/digital-events","tag":"li","active-class":"active"}},[_c('a',[_vm._v("Digital Events")])]),_c('router-link',{attrs:{"to":"/new-digital-event","tag":"li","active-class":"active"}},[_c('a',[_vm._v("Add new Digital Event")])])],1)]):_vm._e(),(
                    _vm.hasPermissions(_vm.userAbilities, {
                        ability: 'manage-moments',
                    })
                )?_c('li',{staticClass:"treeview"},[_vm._m(4),_c('ul',{staticClass:"treeview-menu"},[_c('router-link',{attrs:{"to":"/moments","tag":"li","active-class":"active"}},[_c('a',[_vm._v("All Moments")])]),_c('router-link',{attrs:{"to":"/moment-reports","tag":"li","active-class":"active"}},[_c('a',[_vm._v("Moment Reports")])])],1)]):_vm._e(),(
                    _vm.hasPermissions(_vm.userAbilities, {
                        ability: 'manage-live-stream-details',
                    })
                )?_c('li',{staticClass:"treeview"},[_vm._m(5),_c('ul',{staticClass:"treeview-menu"},[_c('router-link',{attrs:{"to":"/livestreams","tag":"li","active-class":"active"}},[_c('a',[_vm._v("All Live Streams")])]),_c('router-link',{attrs:{"to":"/livestreams/new","tag":"li","active-class":"active"}},[_c('a',[_vm._v("Add new Live Stream")])])],1)]):_vm._e(),(
                    _vm.hasPermissions(_vm.userAbilities, {
                        ability: 'manage-polls',
                    })
                )?_c('li',{staticClass:"treeview"},[_vm._m(6),_c('ul',{staticClass:"treeview-menu"},[_c('router-link',{attrs:{"to":"/polls","tag":"li","active-class":"active"}},[_c('a',[_vm._v("All Polls")])]),_c('router-link',{attrs:{"to":"/polls/new","tag":"li","active-class":"active"}},[_c('a',[_vm._v("Add new Poll")])])],1)]):_vm._e(),(
                    _vm.hasPermissions(_vm.userAbilities, {
                        ability: 'manage-files',
                    })
                )?_c('router-link',{attrs:{"to":"/media-manager","active-class":"active","tag":"li"}},[_c('a',[_c('i',{staticClass:"fa fa-image"}),_c('span',[_vm._v("Media & Files")])])]):_vm._e(),(
                    _vm.hasPermissions(_vm.userAbilities, {
                        ability: 'manage-groups-channels',
                    })
                )?_c('router-link',{attrs:{"to":"/groups-channels","active-class":"active","tag":"li"}},[_c('a',[_c('i',{staticClass:"fa fa-cubes"}),_c('span',[_vm._v("Groups & Channels")])])]):_vm._e(),_c('router-link',{attrs:{"to":"/tags","active-class":"active","tag":"li"}},[_c('a',[_c('i',{staticClass:"fa fa-tags"}),_c('span',[_vm._v("Tags")])])]),(
                    _vm.hasPermissions(_vm.userAbilities, {
                        ability: 'manage-comments',
                    })
                )?_c('li',{staticClass:"treeview"},[_vm._m(7),_c('ul',{staticClass:"treeview-menu"},[_c('router-link',{attrs:{"to":"/comments","tag":"li","active-class":"active"}},[_c('a',[_vm._v("All Comments")])]),_c('router-link',{attrs:{"to":"/reported","tag":"li","active-class":"active"}},[_c('a',[_vm._v("Reported")])])],1)]):_vm._e(),(
                    _vm.hasPermissions(_vm.userAbilities, {
                        ability: 'manage-users',
                    })
                )?_c('li',{staticClass:"treeview"},[_vm._m(8),_c('ul',{staticClass:"treeview-menu"},[_c('router-link',{attrs:{"to":"/users","tag":"li","active-class":"active"}},[_c('a',[_vm._v("All Members")])]),_c('router-link',{attrs:{"to":"/users/deleted","tag":"li","active-class":"active"}},[_c('a',[_vm._v("Deleted Members")])]),_c('router-link',{attrs:{"to":"/new-user","tag":"li","active-class":"active"}},[_c('a',[_vm._v("Add new Member")])]),_c('router-link',{attrs:{"to":{ name: 'code' },"tag":"li","active-class":"active"}},[_c('a',[_vm._v("Unused Registation Code List")])]),_c('router-link',{attrs:{"to":{ name: 'code-generator' },"tag":"li","active-class":"active"}},[_c('a',[_vm._v("Registation Code Generator")])])],1)]):_vm._e(),(
                    _vm.hasPermissions(_vm.userAbilities, {
                        ability: 'manage-inbox',
                    })
                )?_c('router-link',{attrs:{"to":"/inbox","active-class":"active","tag":"li"}},[_c('a',[_c('i',{staticClass:"fa fa-envelope"}),_c('span',[_vm._v("Inbox")]),_c('span',{staticClass:"pull-right-container"},[_c('small',{staticClass:"label pull-right bg-green",domProps:{"textContent":_vm._s(_vm.countNewMessages)}})])])]):_vm._e(),_c('li',{staticClass:"treeview"},[_vm._m(9),_c('ul',{staticClass:"treeview-menu"},[(
                            _vm.hasPermissions(_vm.userAbilities, {
                                ability: 'manage-pages',
                            })
                        )?_c('router-link',{attrs:{"to":"/pages","tag":"li","active-class":"active"}},[_c('a',[_vm._v("Pages")])]):_vm._e(),(
                            _vm.hasPermissions(_vm.userAbilities, {
                                ability: 'manage-system-settings',
                            })
                        )?_c('router-link',{attrs:{"to":"/apis","tag":"li","active-class":"active"}},[_c('a',[_vm._v("API's")])]):_vm._e(),(
                            _vm.hasPermissions(_vm.userAbilities, {
                                ability: 'manage-event-bookings',
                            })
                        )?_c('router-link',{attrs:{"to":"/booking/log","tag":"li","active-class":"active"}},[_c('a',[_vm._v("Booking Log")])]):_vm._e(),(
                            _vm.hasPermissions(_vm.userAbilities, {
                                ability: 'manage-system-users',
                            })
                        )?_c('router-link',{attrs:{"to":"/system-users","tag":"li","active-class":"active"}},[_c('a',[_vm._v("System Users")])]):_vm._e(),(
                            _vm.hasPermissions(_vm.userAbilities, {
                                ability: 'manage-system-settings',
                            })
                        )?_c('router-link',{attrs:{"to":"/mail/settings","tag":"li","active-class":"active"}},[_c('a',[_vm._v("Mail Settings")])]):_vm._e(),(
                            _vm.hasPermissions(_vm.userAbilities, {
                                ability: 'manage-comments',
                            })
                        )?_c('router-link',{attrs:{"to":"/bad-words","tag":"li","active-class":"active"}},[_c('a',[_vm._v("Bad Words")])]):_vm._e(),(
                            _vm.hasPermissions(_vm.userAbilities, {
                                ability: 'manage-system-settings',
                            })
                        )?_c('router-link',{attrs:{"to":"/settings","tag":"li","active-class":"active"}},[_c('a',[_vm._v("General")])]):_vm._e(),(
                            _vm.hasPermissions(_vm.userAbilities, {
                                ability: 'manage-roles',
                            })
                        )?_c('router-link',{attrs:{"to":"/roles","tag":"li","active-class":"active"}},[_c('a',[_vm._v("ACL")])]):_vm._e()],1)])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"#"}},[_c('i',{staticClass:"fa fa-file-text"}),_c('span',[_vm._v("Stories")]),_c('span',{staticClass:"pull-right-container"},[_c('i',{staticClass:"fa fa-angle-left pull-right"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"#"}},[_c('i',{staticClass:"fa fa-play"}),_c('span',[_vm._v("Videos")]),_c('span',{staticClass:"pull-right-container"},[_c('i',{staticClass:"fa fa-angle-left pull-right"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"#"}},[_c('i',{staticClass:"fa fa-podcast"}),_c('span',[_vm._v("Podcasts")]),_c('span',{staticClass:"pull-right-container"},[_c('i',{staticClass:"fa fa-angle-left pull-right"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"#"}},[_c('i',{staticClass:"fa fa-globe"}),_c('span',[_vm._v("Events")]),_c('span',{staticClass:"pull-right-container"},[_c('i',{staticClass:"fa fa-angle-left pull-right"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"#"}},[_c('i',{staticClass:"fa fa-file-image-o"}),_c('span',[_vm._v("Moments")]),_c('span',{staticClass:"pull-right-container"},[_c('i',{staticClass:"fa fa-angle-left pull-right"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"#"}},[_c('i',{staticClass:"fa fa-video-camera"}),_c('span',[_vm._v("Live Streams")]),_c('span',{staticClass:"pull-right-container"},[_c('i',{staticClass:"fa fa-angle-left pull-right"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"#"}},[_c('i',{staticClass:"fa fa-question"}),_c('span',[_vm._v("Polls")]),_c('span',{staticClass:"pull-right-container"},[_c('i',{staticClass:"fa fa-angle-left pull-right"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"#"}},[_c('i',{staticClass:"fa fa-group"}),_c('span',[_vm._v("Comments")]),_c('span',{staticClass:"pull-right-container"},[_c('i',{staticClass:"fa fa-angle-left pull-right"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"#"}},[_c('i',{staticClass:"fa fa-group"}),_c('span',[_vm._v("Members")]),_c('span',{staticClass:"pull-right-container"},[_c('i',{staticClass:"fa fa-angle-left pull-right"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"#"}},[_c('i',{staticClass:"fa fa-cog"}),_c('span',[_vm._v("Settings")]),_c('span',{staticClass:"pull-right-container"},[_c('i',{staticClass:"fa fa-angle-left pull-right"})])])
}]

export { render, staticRenderFns }