import Vue from 'vue'
import App from './App.vue'
import store from './vuex/store'
import router from './router'
import api from './api';
import VueSweetalert2 from 'vue-sweetalert2';
import VTooltip from 'v-tooltip'
import tooltipStyles from '@/assets/scss/tooltip.scss';
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.config.productionTip = false;
Vue.prototype.$api = api;
Vue.use(VueSweetalert2);
Vue.use(VTooltip);

new Vue({
    store,
    router,
    render: h => h(App)
}).$mount('#app');
