<template>
    <div v-if="isAuthenticated && isBootstrapped">
        <AppHeader></AppHeader>
        <AppSidebar></AppSidebar>
        <div class="content-wrapper">
            <slot />
        </div>
        <AppFooter></AppFooter>
    </div>
    <Loader v-else></Loader>
</template>

<script>
    import { hasPermissions } from "@/lib/helpers";
    import { mapGetters, mapActions, } from 'vuex'
    import Loader from '@/components/common/Loader';
    import AppHeader from '@/components/AppHeader';
    import AppSidebar from '@/components/AppSidebar';
    import AppFooter from '@/components/AppFooter';

    export default {
        components: {
            Loader,
            AppHeader,
            AppSidebar,
            AppFooter
        },
        data: function() {
            return {
                isBootstrapped: false,
            };
        },
        created: function () {
            this.getCurrentUser();
        },
        methods: {
            ...mapActions([
                'getUser',
            ]),
            async getCurrentUser() {
                let success = await this.getUser();
                if (success) {
                    if (!hasPermissions(this.userAbilities, this.$route.meta)) {
                        this.$router.push({ name: 'permission-denied', });
                        return;
                    }
                    this.isBootstrapped = true;
                    window.jQuery('body').removeClass().addClass('skin-red sidebar-mini fixed');
                } else {
                    this.$router.push({ name: 'logout', });
                }
            },
        },
        computed: {
            ...mapGetters([
                'isAuthenticated',
                'userAbilities',
            ]),
        },
    }
</script>