<template>
    <header class="main-header">
        <router-link to="/" class="logo">
            <span class="logo-mini"><img src="/logo.png" class="img-circle" alt="Porsche"></span>
            <span class="logo-lg"><img src="/logo.png" class="img-circle" alt="Porsche"> <b>Porsche</b> Circle</span>
        </router-link>
        <nav class="navbar navbar-static-top" role="navigation">
            <a href="#" class="sidebar-toggle" data-toggle="push-menu" role="button">
                <span class="sr-only">Toggle navigation</span>
            </a>
            <div class="navbar-custom-menu">
                <ul class="nav navbar-nav">
                    <li class="dropdown messages-menu">
                        <!-- Menu toggle button -->
                        <a href="#" class="dropdown-toggle" data-toggle="dropdown">
                            <i class="fa fa-envelope-o"></i>
                            <span class="label label-success" v-text="countNewMessages"></span>
                        </a>
                        <ul class="dropdown-menu">
                            <li class="header">You have {{ countNewMessages }} messages</li>
                            <li>
                                <messages-container></messages-container>
                            </li>

                            <li class="footer">
                                <router-link to="/inbox">See All Messages</router-link>
                            </li>
                        </ul>
                    </li>
                    <!-- User Account Menu -->
                    <li class="dropdown user user-menu">
                        <a href="#" class="dropdown-toggle" data-toggle="dropdown">
                            <span class="hidden-xs" v-text="currentUser.full_name"></span>
                        </a>
                        <ul class="dropdown-menu">
                            <li class="user-header">
                                <p class="user-name">
                                    {{ currentUser.full_name }}
                                    <small v-if="!isLoadingRoles" v-text="getRole()"></small>
                                    <small v-if="!isLoadingMarkets">
                                        Market: {{ marketNameFormatter(currentUser.market_id) }}
                                    </small>
                                </p>
                            </li>
                            <li class="user-footer">
                                <router-link to="/logout" class="btn btn-default btn-flat btn-block">Sign out</router-link>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </nav>
    </header>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import MessagesContainer  from '@/components/widgets/MessagesContainer';
    import MarketNameFormatterMixin from '@/components/vuetable/market_name_formatter_mixin';

    export default {
        mixins: [
            MarketNameFormatterMixin,
        ],
        components: {
            MessagesContainer,
        },
        created() {
            this.getRoles();
        },
        methods: {
            ...mapActions([
                'getRoles',
            ]),
            getRole() {
                if (this.roles.findIndex(role => role.name === this.userRoles[0]) > -1) {
                    return this.roles.find(role => role.name === this.userRoles[0]).title;
                }

                return '';
            },
        },
        computed: {
            ...mapGetters([
                'currentUser',
                'countNewMessages',
                'roles',
                'isLoadingRoles',
                'isLoadingMarkets',
                'userRoles',
            ]),
        },
    }
</script>

<style lang="scss">
    .logo-mini img, .logo-lg img {
        max-width: 20px;
        border-radius: 0;
        margin-right: 10px;
    }
    .logo-mini img {
        margin-right: 0;
    }
    .user-name {
        padding-top: 40px;
    }
    .navbar-nav>.messages-menu>.dropdown-menu>li .menu>li>a>h4 {
        margin: 0;
    }
    .navbar-nav>.messages-menu>.dropdown-menu>li .menu>li>a>p {
        margin: 0;
    }
</style>
