import api from '@/api';

const state = {
    titles: {
        isLoading: false,
        data: [],
    },
};

const getters = {
    titles: state => state.titles.data,
    hasTitles: state => state.titles.data.length > 0,
    isLoadingTitles: state => state.titles.isLoading,
};

const mutations = {
    setTitles(state, titles) {
        state.titles.data = titles;
    },
    setLoadingTitles(state, isLoading) {
        state.titles.isLoading = isLoading;
    },
};

const actions = {
    async loadTitles({ getters, commit }) {
        // load from API
        if (!getters.hasTitles && !getters.isLoadingTitles) {
            commit('setLoadingTitles', true);
            let { data: { data: titles } } = await api.get('titles');
            commit('setTitles', titles);
            commit('setLoadingTitles', false);
        }
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};