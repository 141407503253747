import axios from 'axios';
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.common['User-Time'] = encodeURI((new Date()).toString());
axios.defaults.withCredentials = true;
const BASE_URL = process.env.VUE_APP_API;

export default {
    get(path, httpOptions) {
        return axios.get(this.formatUrl(path), httpOptions);
    },
    post(path, data, httpOptions) {
        return axios.post(this.formatUrl(path), data, httpOptions);
    },
    put(path, data, httpOptions) {
        return axios.put(this.formatUrl(path), data, httpOptions);
    },
    patch(path, data, httpOptions) {
        return axios.patch(this.formatUrl(path), data, httpOptions);
    },
    delete(path, httpOptions) {
        return axios.delete(this.formatUrl(path), httpOptions);
    },
    upload(files) {
        let formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            let file = files[i];
            formData.append(`names[${i}]`, file.name);
            formData.append(`files[${i}]`, file.location);
        }
        return this.post('files', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });
    },
    formatUrl(path) {
        return BASE_URL+path;
    },
    startSession() {
        localStorage.setItem('hasSession', '1');
    },
    endSession() {
        localStorage.setItem('hasSession', '0');
    },
    hasSession() {
        return localStorage.getItem('hasSession') === '1';
    },
}
