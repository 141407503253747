import api from '@/api';

const state = {
    'pages': {},
    'isLoading': false,
};

const getters = {
    pages: state => state.pages,
    loadingPages: state => state.isLoading,
};

const mutations = {
    setPages(state, pages) {
        state.pages = pages;
    },
    updateTranslation(state, { body, pageId, languageCode }) {
        state.pages.find(page => { return page.id === pageId; }).translations[languageCode].body = body;
    },
    setLoading(state, isLoading) {
        state.isLoading = isLoading;
    },
    updatePage(state, pageData) {
        const index = state.pages.findIndex(page => { return page.id === pageData.id; });
        state.pages[index] = pageData;
    }
};

const actions = {
    async getPages({ commit }) {
        commit('setLoading', true);
        let { data: { data } } = await api.get('pages?sort=section|asc,order|asc');
        commit('setPages', data);
        commit('setLoading', false);
    },
    async savePage({ commit }, page) {
        let { data: { data } } = await api.patch('pages/'+page.id, page);
        commit('updatePage', data);
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};