import api from '@/api';

const state = {
    channels: {
        isLoading: false,
        data: [],
    },
};

const getters = {
    channels: state => state.channels.data,
    hasChannels: state => state.channels.data.length > 0,
    isLoadingChannels: state => state.channels.isLoading,
};

const mutations = {
    setChannels(state, channels) {
        state.channels.data = channels;
    },
    setLoadingChannels(state, isLoading) {
        state.channels.isLoading = isLoading;
    },
};

const actions = {
    async getChannels({ getters, commit }) {
        if (!getters.hasChannels && !getters.isLoadingChannels) {
            commit('setLoadingChannels', true);
            let { data: { data: channels } } = await api.get('channels');
            commit('setChannels', channels);
            commit('setLoadingChannels', false);
        }
    },
    async reloadChannels({ commit }) {
        commit('setLoadingChannels', true);
        let { data: { data: channels } } = await api.get('channels');
        commit('setChannels', channels);
        commit('setLoadingChannels', false);
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};