<template>
    <main id="app" class="wrapper">
        <component :is="layout">
            <router-view />
        </component>
    </main>
</template>

<script>
    const defaultLayout = 'guest';
    import axios from 'axios';
    window.$ = window.jQuery = require('jquery');
    require('bootstrap');
    require('../node_modules/admin-lte/dist/js/adminlte.js');

    export default {
        name: 'App',
        computed: {
            layout() {
                return (this.$route.meta.layout || defaultLayout) + '-layout';
            }
        },
        created: function () {
            this.interceptHttpStatuses();
            if (process.env.NODE_ENV !== 'development' && process.env.NODE_ENV !== 'production') {
                (function (d, t) {
                    var bh = d.createElement(t), s = d.getElementsByTagName(t)[0];
                    bh.type = 'text/javascript';
                    bh.src = 'https://www.bugherd.com/sidebarv2.js?apikey=gjrxzplwqepawwqxqjz3ia';
                    s.parentNode.insertBefore(bh, s);
                })(document, 'script');
            }
        },
        updated: function () {
            window.jQuery('body').layout('fix');
        },
        methods: {
            interceptHttpStatuses: function () {
                axios.interceptors.response.use(response => {
                    if (201 === response.status) {
                        this.$swal({
                            type: 'success',
                            title: "Successfully created!",
                            showCancelButton: false,
                            timer: 2000,
                        });
                    }

                    if (202 === response.status) {
                        this.$swal({
                            type: 'success',
                            title: "Successfully saved!",
                            showCancelButton: false,
                            timer: 1500,
                        });
                    }

                    if (204 === response.status) {
                        this.$swal({
                            type: 'success',
                            title: "Record successfully deleted!",
                            showCancelButton: false,
                            timer: 1500,
                        });
                    }
                    if (299 === response.status) {
                        this.$swal({
                            type: 'success',
                            title: "Successfully completed!",
                            showCancelButton: false,
                            timer: 2000,
                        });
                    }

                    return response;
                }, error => {
                    if (401 === error?.response.status) {
                        let errorMsg = error.response.data.error || 'Your session has expired! Please, log in again, to continue.';
                        this.$swal({
                            type: 'error',
                            title: 'Oops...',
                            text: errorMsg
                        }).then(() => {
                            this.$store.commit('removeUser');
                            this.$router.push('/login');
                        });

                        return Promise.reject(error);
                    }

                    if (403 === error?.response.status) {
                        this.$swal({
                            type: 'error',
                            title: 'You don\'t have permissions to access this resource.',
                        }).then(() => {
                            this.$router.push('/');
                        });

                        return Promise.reject(error);
                    }

                    if (404 === error?.response.status) {
                        this.$swal({
                            type: 'error',
                            title: 'This resource is not available.',
                        }).then(() => {
                            this.$router.push('/');
                        });

                        return Promise.reject(error);
                    }

                    if (422 === error?.response.status) {
                        let countErrors = Object.keys(error.response.data.errors).length;
                        this.$swal({
                            type: 'error',
                            title: error.response.data.message,
                            text: countErrors === 1 ? `There is 1 unresolved error.` : `There are ${countErrors} unresolved errors.`,
                            showCancelButton: false,
                        });
                        throw {
                            status: 422,
                            errors: error.response.data.errors,
                            error: new Error(error),
                        };
                    }

                    if (500 === error?.response.status) {
                        this.$swal({
                            type: 'error',
                            title: 'Server Error',
                            text: 'An error occurred. Please, try again, or contact support.',
                            showCancelButton: false,
                        });
                    }
                });
            }
        }
    }
</script>

<style lang="less">
    @import "~bootstrap/less/bootstrap";
    @import "~font-awesome/less/font-awesome";
</style>

<style lang="css">
    @import url('~admin-lte/dist/css/AdminLTE.min.css');
    @import url('./assets/skin-porsche.css');
    @import url('./assets/loader.css');
    @import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700,300italic,400italic,600italic');
</style>

<style lang="scss">
    body {
        font-family: $font-primary-family;
    }
</style>
