import api from '@/api';

const state = {
    tags: {
        isCreating: false,
        isLoading: false,
        data: [],
    },
};

const getters = {
    isCreatingTag: state => state.tags.isCreating,
    tags: state => state.tags.data,
    hasTags: state => state.tags.data.length > 0,
    isLoadingTags: state => state.tags.isLoading,
};

const mutations = {
    setTags(state, tags) {
        state.tags.data = tags;
    },
    setLoadingTags(state, isLoading) {
        state.tags.isLoading = isLoading;
    },
    setIsCreatingTag(state, isCreating) {
        state.tags.isCreating = isCreating;
    },
    addTag(state, tag) {
        state.tags.data.push(tag);
    },
};

const actions = {
    async getTags({ getters, commit }) {
        if (!getters.hasTags && !getters.isLoadingTags) {
            commit('setLoadingTags', true);
            let { data: { data: tags } } = await api.get('tags');
            commit('setTags', tags);
            commit('setLoadingTags', false);
        }
    },
    async createTag({ commit, state }, newTag) {

        //  first check if the tag already exist. if so return it, otherwise send request to server to create new one.
        let searchTag = state.tags.data.filter(item => item.name.toLowerCase().indexOf(newTag.toLowerCase()) > -1);
        if (searchTag.length === 1) {
            return searchTag[0];
        }

        commit('setIsCreatingTag', true);
        let { data: { data: tag } } = await api.post('tags', { name:  newTag });
        commit('addTag', tag);
        commit('setIsCreatingTag', false);

        return tag;
    }
};

export default {
    state,
    getters,
    mutations,
    actions,
};