import api from '@/api';

const state = {
    newMessages: 0,
    currentUser: null,
    userAbilities: [],
    userRoles: [],
    full_navigation: true,
    per_page: 10,
    loginInProgress: false,
    loginError: null,
};

const getters = {
    countNewMessages: state => state.newMessages,
    isAuthenticated: state => !!state.currentUser,
    currentUser: state => state.currentUser,
    userAbilities: state => state.userAbilities,
    userRoles: state => state.userRoles,
    perPage: state => state.per_page,
    fullNavigation: state => state.full_navigation,
    loginInProgress: state => state.loginInProgress,
    getLoginError: state => state.loginError,
    hasLoginError: state => state.loginError !== null,
};

const mutations = {
    setUser(state, user) {
        state.currentUser = user;
    },
    setNewMessages(state, newMessages) {
        state.newMessages = newMessages;
    },
    setAbilities(state, userAbilities) {
        state.userAbilities = userAbilities;
    },
    setUserRoles(state, userRoles) {
        state.userRoles = userRoles;
    },
    setPerPage(state, perPage) {
        state.per_page = perPage;
    },
    setFullNavigation(state, fullNavigation) {
        state.full_navigation = fullNavigation;
    },
    removeUser(state) {
        state.currentUser = null;
        state.userAbilities = [];
        state.userRoles = [];
        api.endSession();
    },
    setLoginInProgress(state, isInProgress) {
        state.loginInProgress = isInProgress;
    },
    setLoginError(state, error) {
        state.loginError = error;
    },
    clearLoginError(state) {
        if (state.loginError !== null) {
            state.loginError = null;
        }
    },
};

const actions = {
    async doLogin({ commit }, user) {
        commit('setLoginInProgress', true);
        try {
            await api.post('auth/login/admin', user);
            api.startSession();
        } catch (e) {
            commit('setLoginError', e.response.data.error);
        }
        commit('setLoginInProgress', false);
    },
    async doLogout({ commit }) {
        await api.post('auth/logout');
        commit('removeUser');
    },
    async getUser({ commit }) {
        let { data } = await api.get('user/current');

        if (!data.abilities || !data.roles) {
            return false;
        }

        commit('setUser', data.user);
        commit('setAbilities', data.abilities);
        commit('setUserRoles', data.roles);
        commit('setPerPage', data.per_page);
        commit('setFullNavigation', data.full_navigation);
        commit('setNewMessages', data.new_messages);

        return true;
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};
