import api from '@/api';

const state = {
    countries: {
        isLoading: false,
        data: [],
    },
};

const getters = {
    countries: state => state.countries.data,
    hasCountries: state => state.countries.data.length > 0,
    isLoadingCountries: state => state.countries.isLoading,
};

const mutations = {
    setCountries(state, countries) {
        state.countries.data = countries;
    },
    setLoadingCountries(state, isLoading) {
        state.countries.isLoading = isLoading;
    },
};

const actions = {
    async getCountries({ getters, commit }) {
        if (!getters.hasCountries && !getters.isLoadingCountries) {
            commit('setLoadingCountries', true);
            let { data: { data: countries } } = await api.get('countries');
            commit('setCountries', countries);
            commit('setLoadingCountries', false);
        }
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};