<template>
    <ul v-if="!isLoading" class="menu">
        <li v-for="(message, i) in messages" :key="i">
            <router-link to="/inbox">
                <h4>
                    {{ getMessageAuthor(message) }}
                    <small>
                        <i class="fa fa-clock-o"></i>
                        {{ message.created_at }}
                    </small>
                </h4>
                <p v-text="message.summary"></p>
            </router-link>
        </li>
    </ul>
    <ul v-else class="menu">
        <li>
            <a href="javascript:;">
                <h4>Loading...</h4>
            </a>
        </li>
    </ul>
</template>

<script>
    export default {
        data: function () {
            return {
                messages: [],
                isLoading: false,
            };
        },
        created() {
            this.init();
        },
        methods: {
            async init() {
                this.isLoading = true;
                let { data: { data: messages } } = await this.$api.get('messages?per_page=5&page=1&sort=created_at|desc');
                this.messages = messages;
                this.isLoading = false;
            },
            getMessageAuthor(message) {
                if (message.sender_id > 0 && message.sender) {
                    if (message.sender.first_name !== null && message.sender.last_name !== null) {
                        return message.sender.first_name + ' ' + message.sender.last_name;
                    }

                    if (message.sender.username !== null) {
                        return message.sender.username;
                    }
                }

                return message.sender_email;
            }
        }
    };
</script>
