<template>
    <div>
        <slot />
    </div>
</template>

<script>
    export default {
        mounted: function () {
            window.jQuery('body').removeClass().addClass('hold-transition login-page');
        }
    }
</script>