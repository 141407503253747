import api from '@/api';

const state = {
  isLoading: false,
  languages: [],
};

const getters = {
  languages: state => state.languages,
  loadingLanguages: state => state.isLoading,
};

const mutations = {
  setLanguage(state, languages) {
    state.languages = languages;
  },
  setLoading(state, isLoading) {
    state.isLoading = isLoading;
  },
};

const actions = {
  async getLanguages({ commit }) {
    commit('setLoading', true);
    let languages = JSON.parse(localStorage.getItem('languages'));
    if (!languages) {
      let {
        data: { data },
      } = await api.get('languages');
      languages = data;
      localStorage.setItem('languages', JSON.stringify(languages));
    }
    commit('setLanguage', languages);
    commit('setLoading', false);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
