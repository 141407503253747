<template>
    <aside class="main-sidebar">
        <section class="sidebar">
            <ul ref="tree" class="sidebar-menu">
                <router-link to="/" active-class="active" tag="li" exact>
                    <a>
                        <i class="fa fa-dashboard"></i>
                        <span>Dashboard</span>
                    </a>
                </router-link>
                <router-link
                    v-if="
                        hasPermissions(userAbilities, {
                            ability: 'manage-highlights',
                        })
                    "
                    to="/highlights"
                    active-class="active"
                    tag="li"
                >
                    <a>
                        <i class="fa fa-star-half-full"></i>
                        <span>Highlights</span>
                    </a>
                </router-link>
                <li
                    v-if="
                        hasPermissions(userAbilities, {
                            ability: 'manage-stories',
                        })
                    "
                    class="treeview"
                >
                    <a href="#">
                        <i class="fa fa-file-text"></i>
                        <span>Stories</span>
                        <span class="pull-right-container">
                            <i class="fa fa-angle-left pull-right"></i>
                        </span>
                    </a>
                    <ul class="treeview-menu">
                        <router-link to="/stories" tag="li" active-class="active">
                            <a>All Stories</a>
                        </router-link>
                        <router-link to="/new-story" tag="li" active-class="active">
                            <a>Add new Story</a>
                        </router-link>
                    </ul>
                </li>
                <li
                    v-if="
                        hasPermissions(userAbilities, {
                            ability: 'manage-videos',
                        })
                    "
                    class="treeview"
                >
                    <a href="#">
                        <i class="fa fa-play"></i>
                        <span>Videos</span>
                        <span class="pull-right-container">
                            <i class="fa fa-angle-left pull-right"></i>
                        </span>
                    </a>
                    <ul class="treeview-menu">
                        <router-link to="/videos" tag="li" active-class="active">
                            <a>All Videos</a>
                        </router-link>
                        <router-link to="/videos/new" tag="li" active-class="active">
                            <a>Add new Video</a>
                        </router-link>
                    </ul>
                </li>
                <li
                    v-if="
                        hasPermissions(userAbilities, {
                            ability: 'manage-podcasts',
                        })
                    "
                    class="treeview"
                >
                    <a href="#">
                        <i class="fa fa-podcast"></i>
                        <span>Podcasts</span>
                        <span class="pull-right-container">
                            <i class="fa fa-angle-left pull-right"></i>
                        </span>
                    </a>
                    <ul class="treeview-menu">
                        <router-link to="/podcasts" tag="li" active-class="active">
                            <a>All Podcasts</a>
                        </router-link>
                        <router-link to="/podcasts/new" tag="li" active-class="active">
                            <a>Add new Podcast</a>
                        </router-link>
                    </ul>
                </li>
                <li
                    v-if="
                        hasPermissions(userAbilities, {
                            ability: 'manage-event-details',
                        })
                    "
                    class="treeview"
                >
                    <a href="#">
                        <i class="fa fa-globe"></i>
                        <span>Events</span>
                        <span class="pull-right-container">
                            <i class="fa fa-angle-left pull-right"></i>
                        </span>
                    </a>
                    <ul class="treeview-menu">
                        <router-link to="/events" tag="li" active-class="active">
                            <a>Events</a>
                        </router-link>
                        <router-link to="/new-event" tag="li" active-class="active">
                            <a>Add new Event</a>
                        </router-link>
                        <router-link to="/digital-events" tag="li" active-class="active">
                            <a>Digital Events</a>
                        </router-link>
                        <router-link to="/new-digital-event" tag="li" active-class="active">
                            <a>Add new Digital Event</a>
                        </router-link>
                    </ul>
                </li>
                <li
                    v-if="
                        hasPermissions(userAbilities, {
                            ability: 'manage-moments',
                        })
                    "
                    class="treeview"
                >
                    <a href="#">
                        <i class="fa fa-file-image-o"></i>
                        <span>Moments</span>
                        <span class="pull-right-container">
                            <i class="fa fa-angle-left pull-right"></i>
                        </span>
                    </a>
                    <ul class="treeview-menu">
                        <router-link to="/moments" tag="li" active-class="active">
                            <a>All Moments</a>
                        </router-link>
                        <router-link to="/moment-reports" tag="li" active-class="active">
                            <a>Moment Reports</a>
                        </router-link>
                    </ul>
                </li>
                <li
                    v-if="
                        hasPermissions(userAbilities, {
                            ability: 'manage-live-stream-details',
                        })
                    "
                    class="treeview"
                >
                    <a href="#">
                        <i class="fa fa-video-camera"></i>
                        <span>Live Streams</span>
                        <span class="pull-right-container">
                            <i class="fa fa-angle-left pull-right"></i>
                        </span>
                    </a>
                    <ul class="treeview-menu">
                        <router-link to="/livestreams" tag="li" active-class="active">
                            <a>All Live Streams</a>
                        </router-link>
                        <router-link to="/livestreams/new" tag="li" active-class="active">
                            <a>Add new Live Stream</a>
                        </router-link>
                    </ul>
                </li>
                <li
                    v-if="
                        hasPermissions(userAbilities, {
                            ability: 'manage-polls',
                        })
                    "
                    class="treeview"
                >
                    <a href="#">
                        <i class="fa fa-question"></i>
                        <span>Polls</span>
                        <span class="pull-right-container">
                            <i class="fa fa-angle-left pull-right"></i>
                        </span>
                    </a>
                    <ul class="treeview-menu">
                        <router-link to="/polls" tag="li" active-class="active">
                            <a>All Polls</a>
                        </router-link>
                        <router-link to="/polls/new" tag="li" active-class="active">
                            <a>Add new Poll</a>
                        </router-link>
                    </ul>
                </li>
                <router-link
                    v-if="
                        hasPermissions(userAbilities, {
                            ability: 'manage-files',
                        })
                    "
                    to="/media-manager"
                    active-class="active"
                    tag="li"
                >
                    <a>
                        <i class="fa fa-image"></i>
                        <span>Media & Files</span>
                    </a>
                </router-link>
                <router-link
                    v-if="
                        hasPermissions(userAbilities, {
                            ability: 'manage-groups-channels',
                        })
                    "
                    to="/groups-channels"
                    active-class="active"
                    tag="li"
                >
                    <a>
                        <i class="fa fa-cubes"></i>
                        <span>Groups & Channels</span>
                    </a>
                </router-link>
                <router-link to="/tags" active-class="active" tag="li">
                    <a>
                        <i class="fa fa-tags"></i>
                        <span>Tags</span>
                    </a>
                </router-link>
                <li
                    v-if="
                        hasPermissions(userAbilities, {
                            ability: 'manage-comments',
                        })
                    "
                    class="treeview"
                >
                    <a href="#">
                        <i class="fa fa-group"></i>
                        <span>Comments</span>
                        <span class="pull-right-container">
                            <i class="fa fa-angle-left pull-right"></i>
                        </span>
                    </a>
                    <ul class="treeview-menu">
                        <router-link to="/comments" tag="li" active-class="active">
                            <a>All Comments</a>
                        </router-link>
                        <router-link to="/reported" tag="li" active-class="active">
                            <a>Reported</a>
                        </router-link>
                    </ul>
                </li>
                <li
                    v-if="
                        hasPermissions(userAbilities, {
                            ability: 'manage-users',
                        })
                    "
                    class="treeview"
                >
                    <a href="#">
                        <i class="fa fa-group"></i>
                        <span>Members</span>
                        <span class="pull-right-container">
                            <i class="fa fa-angle-left pull-right"></i>
                        </span>
                    </a>
                    <ul class="treeview-menu">
                        <router-link to="/users" tag="li" active-class="active">
                            <a>All Members</a>
                        </router-link>
                        <router-link to="/users/deleted" tag="li" active-class="active">
                            <a>Deleted Members</a>
                        </router-link>
                        <router-link to="/new-user" tag="li" active-class="active">
                            <a>Add new Member</a>
                        </router-link>
                        <router-link :to="{ name: 'code' }" tag="li" active-class="active">
                            <a>Unused Registation Code List</a>
                        </router-link>
                        <router-link
                            :to="{ name: 'code-generator' }"
                            tag="li"
                            active-class="active"
                        >
                            <a>Registation Code Generator</a>
                        </router-link>
                    </ul>
                </li>
                <router-link
                    v-if="
                        hasPermissions(userAbilities, {
                            ability: 'manage-inbox',
                        })
                    "
                    to="/inbox"
                    active-class="active"
                    tag="li"
                >
                    <a>
                        <i class="fa fa-envelope"></i>
                        <span>Inbox</span>
                        <span class="pull-right-container">
                            <small class="label pull-right bg-green" v-text="countNewMessages"></small>
                        </span>
                    </a>
                </router-link>
                <li class="treeview">
                    <a href="#">
                        <i class="fa fa-cog"></i>
                        <span>Settings</span>
                        <span class="pull-right-container">
                            <i class="fa fa-angle-left pull-right"></i>
                        </span>
                    </a>
                    <ul class="treeview-menu">
                        <router-link
                            v-if="
                                hasPermissions(userAbilities, {
                                    ability: 'manage-pages',
                                })
                            "
                            to="/pages"
                            tag="li"
                            active-class="active"
                        >
                            <a>Pages</a>
                        </router-link>
                        <router-link
                            v-if="
                                hasPermissions(userAbilities, {
                                    ability: 'manage-system-settings',
                                })
                            "
                            to="/apis"
                            tag="li"
                            active-class="active"
                        >
                            <a>API's</a>
                        </router-link>
                        <!--<router-link to="/stats" tag="li" active-class="active" v-if="hasPermissions(userAbilities, { ability: 'access-stats' })">-->
                        <!--<a>Statistics</a>-->
                        <!--</router-link>-->
                        <router-link
                            v-if="
                                hasPermissions(userAbilities, {
                                    ability: 'manage-event-bookings',
                                })
                            "
                            to="/booking/log"
                            tag="li"
                            active-class="active"
                        >
                            <a>Booking Log</a>
                        </router-link>
                        <router-link
                            v-if="
                                hasPermissions(userAbilities, {
                                    ability: 'manage-system-users',
                                })
                            "
                            to="/system-users"
                            tag="li"
                            active-class="active"
                        >
                            <a>System Users</a>
                        </router-link>
                        <router-link
                            v-if="
                                hasPermissions(userAbilities, {
                                    ability: 'manage-system-settings',
                                })
                            "
                            to="/mail/settings"
                            tag="li"
                            active-class="active"
                        >
                            <a>Mail Settings</a>
                        </router-link>
                        <!--<router-link to="/mail/templates" tag="li" active-class="active" v-if="hasPermissions(userAbilities, { ability: 'manage-system-settings' })">-->
                        <!--<a>Mail Templates</a>-->
                        <!--</router-link>-->

                        <router-link
                            v-if="
                                hasPermissions(userAbilities, {
                                    ability: 'manage-comments',
                                })
                            "
                            to="/bad-words"
                            tag="li"
                            active-class="active"
                        >
                            <a>Bad Words</a>
                        </router-link>

                        <router-link
                            v-if="
                                hasPermissions(userAbilities, {
                                    ability: 'manage-system-settings',
                                })
                            "
                            to="/settings"
                            tag="li"
                            active-class="active"
                        >
                            <a>General</a>
                        </router-link>
                        <router-link
                            v-if="
                                hasPermissions(userAbilities, {
                                    ability: 'manage-roles',
                                })
                            "
                            to="/roles"
                            tag="li"
                            active-class="active"
                        >
                            <a>ACL</a>
                        </router-link>
                        <!--<router-link to="/language-management" tag="li" active-class="active" v-if="hasPermissions(userAbilities, { ability: 'manage-system-languages' })">-->
                        <!--<a>Language Management</a>-->
                        <!--</router-link>-->
                    </ul>
                </li>
            </ul>
        </section>
    </aside>
</template>

<script>
import { hasPermissions } from '@/lib/helpers';
import { mapGetters } from 'vuex';

export default {
  mounted() {
    window.jQuery(this.$refs['tree']).tree();
    if (this.fullNavigation === 0) {
      window.jQuery('body').addClass('sidebar-collapse sideabar-mini');
    }
  },
  methods: {
    hasPermissions,
  },
  computed: {
    ...mapGetters(['countNewMessages', 'userAbilities', 'fullNavigation']),
  },
  watch: {
    fullNavigation(isFull) {
      if (isFull === 0) {
        window.jQuery('body').addClass('sidebar-collapse sideabar-mini');
      } else {
        window.jQuery('body').removeClass('sidebar-collapse sideabar-mini');
      }
    },
  },
};
</script>
