import api from '@/api';

const state = {
    users: {
        isLoading: false,
        data: [],
    },
};

const getters = {
    users: state => state.users.data,
    hasUsers: state => state.users.data.length > 0,
    isLoadingUsers: state => state.users.isLoading,
};

const mutations = {
    setUsers(state, users) {
        state.users.data = users;
    },
    setLoadingUsers(state, isLoading) {
        state.users.isLoading = isLoading;
    },
};

const actions = {
    async loadUsers({ getters, commit }) {
        // load from API
        if (!getters.hasUsers && !getters.isLoadingUsers) {
            commit('setLoadingUsers', true);
            let { data: { data: users } } = await api.get('users');
            commit('setUsers', users);
            commit('setLoadingUsers', false);
        }
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};