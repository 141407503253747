import Vue from 'vue';
import Router from 'vue-router';
import api from '@/api';

// Layouts
import Authenticated from '@/layouts/Authenticated';
import Guest from '@/layouts/Guest';

Vue.component('AuthLayout', Authenticated);
Vue.component('GuestLayout', Guest);
Vue.use(Router);

const ifNotAuthenticated = (to, from, next) => {
  if (!api.hasSession()) {
    next();
    return;
  }
  next('/');
};

const ifAuthenticated = (to, from, next) => {
  if (api.hasSession()) {
    next();
    return;
  }
  next('/login');
};

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/login',
      name: 'login',
      meta: {
        layout: 'guest',
      },
      component: () => import('./views/Login'),
      beforeEnter: ifNotAuthenticated,
    },
    {
      path: '/logout',
      name: 'logout',
      meta: {
        layout: 'guest',
      },
      component: () => import('./views/Logout'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/403',
      name: 'permission-denied',
      meta: {
        layout: 'guest',
      },
      component: () => import('./views/PermissionDenied'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/',
      name: 'dashboard',
      meta: {
        layout: 'auth',
      },
      component: () => import('./views/Dashboard/Dashboard'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/highlights',
      name: 'highlights',
      meta: {
        layout: 'auth',
        ability: 'manage-highlights',
      },
      component: () => import('./views/Highlights'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/stories',
      name: 'stories',
      meta: {
        layout: 'auth',
        ability: 'manage-stories',
      },
      component: () => import('./views/Stories/Stories'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/new-story',
      name: 'new-story',
      meta: {
        layout: 'auth',
        ability: 'manage-stories',
      },
      component: () => import('./views/Stories/NewStory'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/story/:id',
      name: 'edit-story',
      meta: {
        layout: 'auth',
        ability: 'manage-stories',
      },
      component: () => import('./views/Stories/Story'),
      props: true,
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/videos',
      name: 'videos',
      meta: {
        layout: 'auth',
        ability: 'manage-videos',
      },
      component: () => import('./views/Videos/Videos'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/videos/new',
      name: 'new-video',
      meta: {
        layout: 'auth',
        ability: 'manage-videos',
      },
      component: () => import('./views/Videos/NewVideo'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/videos/:id',
      name: 'edit-video',
      meta: {
        layout: 'auth',
        ability: 'manage-videos',
      },
      component: () => import('./views/Videos/Video'),
      props: true,
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/podcasts',
      name: 'podcasts',
      meta: {
        layout: 'auth',
        ability: 'manage-podcasts',
      },
      component: () => import('./views/Podcasts/Podcasts'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/podcasts/new',
      name: 'new-podcast',
      meta: {
        layout: 'auth',
        ability: 'manage-podcasts',
      },
      component: () => import('./views/Podcasts/NewPodcast'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/podcasts/:id',
      name: 'edit-podcast',
      meta: {
        layout: 'auth',
        ability: 'manage-podcasts',
      },
      component: () => import('./views/Podcasts/Podcast'),
      props: true,
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/events',
      name: 'events',
      meta: {
        layout: 'auth',
        ability: 'manage-event-details',
      },
      component: () => import('./views/Events/Events'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/new-event',
      name: 'new-event',
      meta: {
        layout: 'auth',
        ability: 'manage-event-details',
      },
      component: () => import('./views/Events/NewEvent'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/event/:id',
      name: 'edit-event',
      meta: {
        layout: 'auth',
        ability: 'manage-event-details',
      },
      component: () => import('./views/Events/Event'),
      props: true,
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/comments',
      name: 'comments',
      meta: {
        layout: 'auth',
        ability: 'manage-comments',
      },
      component: () => import('./views/Comments/Comments'),
      props: true,
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/livestreams',
      name: 'live-streams',
      meta: {
        layout: 'auth',
        ability: 'manage-live-stream-details',
      },
      component: () => import('./views/LiveStreams/LiveStreams'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/livestreams/new',
      name: 'new-live-stream',
      meta: {
        layout: 'auth',
        ability: 'manage-live-stream-details',
      },
      component: () => import('./views/LiveStreams/NewLiveStream'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/livestreams/:id',
      name: 'edit-live-stream',
      meta: {
        layout: 'auth',
        ability: 'manage-live-stream-details',
      },
      component: () => import('./views/LiveStreams/LiveStream'),
      props: true,
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/digital-events',
      name: 'digital-events',
      meta: {
        layout: 'auth',
        ability: 'manage-event-details',
      },
      component: () => import('./views/Events/Events'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/new-digital-event',
      name: 'new-digital-event',
      meta: {
        layout: 'auth',
        ability: 'manage-event-details',
      },
      component: () => import('./views/Events/NewEvent'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/digital-event/:id',
      name: 'edit-digital-event',
      meta: {
        layout: 'auth',
        ability: 'manage-event-details',
      },
      component: () => import('./views/Events/Event'),
      props: true,
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/polls',
      name: 'polls',
      meta: {
        layout: 'auth',
        ability: 'manage-polls',
      },
      component: () => import('./views/Polls/Polls'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/polls/new',
      name: 'new-poll',
      meta: {
        layout: 'auth',
        ability: 'manage-polls',
      },
      component: () => import('./views/Polls/Poll'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/poll/:id',
      name: 'edit-poll',
      meta: {
        layout: 'auth',
        ability: 'manage-polls',
      },
      component: () => import('./views/Polls/Poll'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/poll/:id/results',
      name: 'poll-results',
      meta: {
        layout: 'auth',
        ability: 'manage-polls',
      },
      component: () => import('./views/Polls/PollResults'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/media-manager',
      name: 'media-manager',
      meta: {
        layout: 'auth',
        ability: 'manage-files',
      },
      component: () => import('./views/Files'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/groups-channels',
      name: 'groups-channels',
      meta: {
        layout: 'auth',
        ability: 'manage-groups-channels',
      },
      component: () => import('./views/GroupsChannels/GroupsChannels'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/tags',
      name: 'tags',
      meta: {
        layout: 'auth',
      },
      props: true,
      component: () => import('./views/Tags/Tags'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/moments',
      name: 'moments',
      meta: {
        layout: 'auth',
        ability: 'manage-moments',
      },
      component: () => import('./views/Moments/Moments'),
      props: true,
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/moment-reports',
      name: 'moment-reports',
      meta: {
        layout: 'auth',
        ability: 'manage-moments',
      },
      component: () => import('./views/Moments/MomentReports'),
      props: true,
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/reported',
      name: 'reported-comments',
      meta: {
        layout: 'auth',
        ability: 'manage-comments',
      },
      component: () => import('./views/Comments/ReportedComments'),
      props: true,
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/users',
      name: 'users',
      meta: {
        layout: 'auth',
        ability: 'manage-users',
      },
      component: () => import('./views/Users/Users'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/users/deleted',
      name: 'users-deleted',
      meta: {
        layout: 'auth',
        ability: 'manage-users',
      },
      component: () => import('./views/Users/Trashed'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/new-user',
      name: 'new-user',
      meta: {
        layout: 'auth',
        ability: 'manage-users',
      },
      component: () => import('./views/Users/User'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/registration-code',
      name: 'code',
      meta: {
        layout: 'auth',
        ability: 'manage-users',
      },
      props: true,
      component: () => import('./views/RegistrationCode/List'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/registration-code/generator',
      name: 'code-generator',
      meta: {
        layout: 'auth',
        ability: 'manage-users',
      },
      props: true,
      component: () => import('./views/RegistrationCode/Generator'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/user/:id',
      name: 'edit-user',
      meta: {
        layout: 'auth',
        ability: 'manage-users',
      },
      props: true,
      component: () => import('./views/Users/User'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/inbox',
      name: 'inbox',
      meta: {
        layout: 'auth',
        ability: 'manage-inbox',
      },
      component: () => import('./views/Inbox'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/pages',
      name: 'pages',
      meta: {
        layout: 'auth',
        ability: 'manage-pages',
      },
      component: () => import('./views/Settings/Pages/Pages'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/pages/create',
      name: 'new-page',
      meta: {
        layout: 'auth',
        ability: 'manage-pages',
      },
      component: () => import('./views/Settings/Pages/Page'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/pages/edit/:id',
      name: 'edit-page',
      meta: {
        layout: 'auth',
        ability: 'manage-pages',
      },
      props: true,
      component: () => import('./views/Settings/Pages/Page'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/apis',
      name: 'apis',
      meta: {
        layout: 'auth',
        ability: 'manage-system-settings',
      },
      component: () => import('./views/Settings/Apis/Apis'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/stats',
      name: 'stats',
      meta: {
        layout: 'auth',
        ability: 'access-stats',
      },
      component: () => import('./views/Settings/Statistics'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/booking/log',
      name: 'booking-log',
      meta: {
        layout: 'auth',
        ability: 'manage-event-bookings',
      },
      component: () => import('./views/Settings/BookingLog'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/system-users',
      name: 'system-users',
      meta: {
        layout: 'auth',
        ability: 'manage-system-users',
      },
      component: () => import('./views/Settings/SystemUsers/Users'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/system-users/new',
      name: 'new-system-user',
      meta: {
        layout: 'auth',
        ability: 'manage-system-users',
      },
      component: () => import('./views/Users/User'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/system-user/:id',
      name: 'edit-system-user',
      meta: {
        layout: 'auth',
        ability: 'manage-system-users',
      },
      component: () => import('./views/Users/User'),
      props: true,
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/mail/settings',
      name: 'mail-settings',
      meta: {
        layout: 'auth',
        ability: 'manage-system-settings',
      },
      component: () => import('./views/Settings/Mail/MailSettings'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/mail/templates',
      name: 'mail-templates',
      meta: {
        layout: 'auth',
        ability: 'manage-system-settings',
      },
      component: () => import('./views/Settings/Mail/MailTemplates'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/bad-words',
      name: 'bad-words',
      meta: {
        layout: 'auth',
        ability: 'manage-system-settings',
      },
      component: () => import('./views/Settings/BadWords'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/mail/templates/:id',
      name: 'edit-mail-template',
      meta: {
        layout: 'auth',
        ability: 'manage-system-settings',
      },
      component: () => import('./views/Settings/Mail/MailTemplate'),
      props: true,
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/settings',
      name: 'general-settings',
      meta: {
        layout: 'auth',
        ability: 'manage-system-settings',
      },
      component: () => import('./views/Settings/GeneralSettings'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/roles',
      name: 'roles',
      meta: {
        layout: 'auth',
        ability: 'manage-roles',
      },
      component: () => import('./views/Settings/ACL/Roles'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/roles/new',
      name: 'new-role',
      meta: {
        layout: 'auth',
        ability: 'manage-roles',
      },
      component: () => import('./views/Settings/ACL/Role'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/roles/edit/:id',
      name: 'edit-role',
      meta: {
        layout: 'auth',
        ability: 'manage-roles',
      },
      props: true,
      component: () => import('./views/Settings/ACL/Role'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/language-management',
      name: 'language-management',
      meta: {
        layout: 'auth',
        ability: 'manage-system-languages',
      },
      component: () => import('./views/Settings/LanguageManagement'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: '*',
      component: () => import('./views/PageNotFound'),
    },
  ],
});
