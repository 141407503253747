import isString from 'lodash/isString';

export function ucfirst(value) {
    return value.charAt(0).toUpperCase() + value.slice(1);
}

export function hasPermissions(userAbilities, routeMeta) {
    if (userAbilities.length === 1 && userAbilities[0] === '*') {
        return true;
    }
    if (routeMeta.ability === undefined) {
        return true;
    }

    if (isString(routeMeta.ability)) {
        if (userAbilities.findIndex((item) => item.toLowerCase() === routeMeta.ability.toLowerCase()) > -1) {
            return true;
        }
    }

    return false;
}